import { render, staticRenderFns } from "./BodyContent.vue?vue&type=template&id=697f6ccb&"
import script from "./BodyContent.vue?vue&type=script&lang=js&"
export * from "./BodyContent.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/2solar-software/frontend-application-service/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('697f6ccb')) {
      api.createRecord('697f6ccb', component.options)
    } else {
      api.reload('697f6ccb', component.options)
    }
    module.hot.accept("./BodyContent.vue?vue&type=template&id=697f6ccb&", function () {
      api.rerender('697f6ccb', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/containers/portal/components/content/BodyContent.vue"
export default component.exports