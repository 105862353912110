import { render, staticRenderFns } from "./RouterViewContainer.vue?vue&type=template&id=d3b20632&"
import script from "./RouterViewContainer.vue?vue&type=script&lang=js&"
export * from "./RouterViewContainer.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/builds/2solar-software/frontend-application-service/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d3b20632')) {
      api.createRecord('d3b20632', component.options)
    } else {
      api.reload('d3b20632', component.options)
    }
    module.hot.accept("./RouterViewContainer.vue?vue&type=template&id=d3b20632&", function () {
      api.rerender('d3b20632', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/js/containers/portal/components/content/RouterViewContainer.vue"
export default component.exports